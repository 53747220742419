@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import Geist Fonts */
@font-face {
  font-family: "Geist Black";
  src: url("fonts/Geist-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Geist SemiBold";
  src: url("fonts/Geist-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Geist Light";
  src: url("fonts/Geist-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Geist Mono";
  src: url("fonts/GeistMono-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Default Font Styles */
body {
  font-family: "Geist SemiBold", sans-serif;
}

/* Tailwind Base Layer (Optional for future customization) */
/* @layer base {
    @font-face {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(/fonts/Roboto.woff2) format('woff2');
    }
} */

/* Legacy Bebas Neue Font */
@font-face {
  font-family: "BebasNeueRegular";
  src: local("BebasNeueRegular"),
       url("fonts/BebasNeue-Regular.ttf") format("truetype");
  font-weight: normal;
}

/* 🎨 Styling Components */
.applicant-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  font-family: "Geist SemiBold", sans-serif;
}

.applicant-info {
  background-color: #f9f9f9;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Geist SemiBold", sans-serif;
}

.progress-bar {
  display: flex;
  gap: 0.5rem;
}

.progress-step {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: bold;
  font-family: "Geist Black", sans-serif;
}

.progress-step.completed {
  background-color: #4caf50;
  color: #fff;
}
